import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Button,
  Table,
  Label,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import axios from "axios"
import ReactPaginate from "react-paginate"
import { ToastContainer, toast } from "react-toastify"
import { URLS } from "../../../Url"

const Staff = () => {
  const [show, setshow] = useState(false)
  const [show1, setshow1] = useState(false)
  const toggle = () => setshow1(!show1)

  const [files, setFiles] = useState([])
  const [files1, setFiles1] = useState([])

  const changeHandler = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png"|| type == "JPG"||  type == "JPEG"||type == "PNG" ) {
      setFiles(e.target.files)
    } else {
      e.target.value = null
      toast("file format not supported.Pls choose Image")
    }
  }

  const changeHandler1 = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png"|| type == "JPG"||  type == "JPEG"||type == "PNG" ) {
      setFiles1(e.target.files)
    } else {
      e.target.value = null
      toast("file format not supported.Pls choose Image")
    }
  }

  const [form, setform] = useState([])
  const [users, setusers] = useState([])
  const [form1, setform1] = useState([])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token
  var datasd = data.hospitalId

  const handleChange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }
  const handleChange1 = e => {
    let myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const Get = () => {
    var token = datas
    axios
      .post(
        URLS.GetTeam,
        {  },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setusers(res.data.teams)
      })
  }

  const custsearch = e => {
    const myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)

    const token = datas
    console.log(token)
    axios
      .post(
        URLS.GetTeamSearch + `${e.target.value}`,
        { },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          if (res.status === 200) {
            setusers(res.data.teams)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const Add = () => {
    var token = datas

    const dataArray = new FormData()
    dataArray.append("name", form.name)
    dataArray.append("designation", form.designation)
    dataArray.append("description", form.description)
    dataArray.append("link", form.link)

    for (let i = 0; i < files.length; i++) {
      dataArray.append("image", files[i])
    }

    axios
      .post(URLS.AddTeam, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            console.log(res.data)
            toast(res.data.message)
            setform("")
            setFiles({image:''})
            Get()
            setshow(false)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const formsubmit = e => {
    e.preventDefault()
    Add()
  }

  const Update = () => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("name", form1.name)
    dataArray.append("designation", form1.designation)
    dataArray.append("description", form1.description)
    dataArray.append("link", form1.link)

    for (let i = 0; i < files1.length; i++) {
      dataArray.append("image", files1[i])
    }

    axios
      .put(URLS.UpdateTeam + form1._id, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            console.log(res.data)
            toast(res.data.message)
            setform1("")
            setFiles1({image:''})
            Get()
            setshow1(false)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const formeditsubmit = e => {
    e.preventDefault()
    Update()
  }

  const usedata = datal => {
    setshow1(true)
    setform1(datal)
  }

  useEffect(() => {
    Get()
  }, [])

  const [listPerPage] = useState(5)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = users.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(users.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const manageDelete = data => {
    const confirmBox = window.confirm("Do you really want to Delete?")
    if (confirmBox === true) {
      Delete(data)
    }
  }

  const Delete = datau => {
    var token = datas
    var remid = datau._id
    axios
      .delete(URLS.DeleteTeam + remid, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            Get()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Star Routes" breadcrumbItem="Team" />

          <Row>
            <Col>
              {show == true ? (
                <Card className="p-4">
                  <Form
                    onSubmit={e => {
                      formsubmit(e)
                    }}
                  >
                    <h5>Add New Team</h5>
                    <Row className="pt-3">
                      <Col md="4">
                        <Label> Name</Label>{" "}
                        <span className="text-danger">*</span>
                        <Input
                          name="name"
                          onChange={e => {
                            handleChange(e)
                          }}
                         
                          value={form.name}
                          required
                          type="text"
                          placeholder="Enter Name"
                        />
                      </Col>
                      <Col md="4">
                        <Label>Designation</Label>{" "}
                        <span className="text-danger">*</span>
                        <Input
                          name="designation"
                          onChange={e => {
                            handleChange(e)
                          }}
                          value={form.designation}
                          required
                          type="text"
                          placeholder="Enter Designation"
                        />
                      </Col>
                      <Col lg="4">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Image
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="file"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter image"
                            name="image"
                            onChange={changeHandler}
                            required
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Description <span className="text-danger">*</span>
                          </Label>
                          <textarea
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Description"
                            required
                            rows={3}
                            value={form.description}
                            name="description"
                            onChange={e => {
                              handleChange(e)
                            }}
                          />
                        </div>
                      </Col>{" "}
                      <Col md="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                          Email Id 
                          </Label>
                          <textarea
                            type="url"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Email Id "
                            rows={3}
                            value={form.link}
                            name="link"
                            onChange={e => {
                              handleChange(e)
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                    <div className="text-end">
                      <Button type="submit" color="primary m-1" outline>
                        Submit <i className="bx bx-check-circle"></i>
                      </Button>
                      <Button
                        type="button"
                        onClick={() => {
                          setshow(!show)
                        }}
                        color="danger m-1"
                        outline
                      >
                        Cancel <i className="bx bx-x-circle"></i>
                      </Button>
                    </div>
                  </Form>
                </Card>
              ) : (
                ""
              )}
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <Button
                        onClick={() => {
                          setshow(!show)
                        }}
                        color="primary"
                      >
                        New Team <i className="bx bx-user-plus"></i>
                      </Button>
                    </Col>
                    <Col>
                      <div style={{ float: "right" }}>
                        <Input
                          name="search"
                          value={form.search}
                          onChange={custsearch}
                          type="search"
                          placeholder="Search..."
                        />
                      </div>
                    </Col>
                  </Row>

                  <div className="table-rep-plugin mt-4 table-responsive">
                    <Table hover bordered responsive>
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Image</th>
                          <th>Name</th>
                          <th>Designation</th>
                          <th>Description</th>
                          <th>Email Id</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {lists.map((data, key) => (
                          <tr key={key}>
                            <th scope="row">
                              {(pageNumber - 1) * 5 + key + 6}
                            </th>
                            <td>
                              <img
                                src={URLS.Base + data.image}
                                height="60px"
                                width="80px"
                              ></img>
                            </td>
                            <td>{data.name}</td>
                            <td>{data.designation}</td>
                            <td>{data.description}</td>
                            <td>{data.link}</td>
                            <td>
                              <Button
                                onClick={() => {
                                  usedata(data)
                                }}
                                size="sm"
                                className="m-1"
                                outline
                                color="success"
                              >
                                <i
                                  style={{ fontSize: " 14px" }}
                                  className="bx bx-edit"
                                ></i>
                              </Button>

                              <Button
                                size="sm"
                                className="m-1"
                                outline
                                color="danger"
                                onClick={() => {
                                  manageDelete(data)
                                }}
                              >
                                <i
                                  style={{ fontSize: " 14px" }}
                                  className="bx bx-trash"
                                ></i>
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <Col sm="12">
                      <div
                        className="d-flex mt-3 mb-1"
                        style={{ float: "right" }}
                      >
                        <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          pageCount={pageCount}
                          onPageChange={changePage}
                          containerClassName={"pagination"}
                          previousLinkClassName={"previousBttn"}
                          nextLinkClassName={"nextBttn"}
                          disabledClassName={"disabled"}
                          activeClassName={"active"}
                          total={lists.length}
                        />
                      </div>
                    </Col>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <ToastContainer />
      </div>
      <Modal size="lg" isOpen={show1} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>Edit Team Details</ModalHeader>
        <ModalBody>
          <Form
            onSubmit={e => {
              formeditsubmit(e)
            }}
          >
            <Row className="pt-3">
              <Col md="4">
                <Label> Name</Label> <span className="text-danger">*</span>
                <Input
                  name="name"
                  onChange={e => {
                    handleChange1(e)
                  }}
                 
                  value={form1.name}
                  required
                  type="text"
                  placeholder="Enter Name"
                />
              </Col>
              <Col md="4">
                <Label>Designation</Label>{" "}
                <span className="text-danger">*</span>
                <Input
                  name="designation"
                  onChange={e => {
                    handleChange1(e)
                  }}
                  value={form1.designation}
                  required
                  type="text"
                  placeholder="Enter Designation"
                />
              </Col>
              <Col lg="4">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    Image
                    <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="file"
                    className="form-control"
                    id="basicpill-firstname-input1"
                    placeholder="Enter image"
                    name="image"
                    onChange={changeHandler1}
                    
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    Description <span className="text-danger">*</span>
                  </Label>
                  <textarea
                    type="text"
                    className="form-control"
                    id="basicpill-firstname-input1"
                    placeholder="Enter Description"
                    required
                    rows={3}
                    value={form1.description}
                    name="description"
                    onChange={e => {
                      handleChange1(e)
                    }}
                  />
                </div>
              </Col>{" "}
              <Col md="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                 Email Id  <span className="text-danger">*</span>
                  </Label>
                  <textarea
                    type="url"
                    className="form-control"
                    id="basicpill-firstname-input1"
                    placeholder="Enter Email Id "
                    rows={3}
                    value={form1.link}
                    name="link"
                    onChange={e => {
                      handleChange1(e)
                    }}
                  />
                </div>
              </Col>
            </Row>
            <div className="text-end mt-3">
              <Button type="submit" color="primary m-1" outline>
                Submit <i className="bx bx-check-circle"></i>
              </Button>
              <Button
                type="button"
                onClick={() => {
                  setshow1(!show1)
                }}
                color="danger m-1"
                outline
              >
                Cancel <i className="bx bx-x-circle"></i>
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default Staff
