import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  CardHeader,
  CardTitle,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { URLS } from "../../Url"
import axios from "axios"

function index() {
  const [users, setusers] = useState([])

  const [users1, setusers1] = useState([])

  useEffect(() => {
    Get()
  }, [])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const Get = () => {
    var token = datas
    axios
      .post(
        URLS.GetDashboard,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setusers(res.data)
        setusers1(res.data.latestEnquiries)
      })
  }

  const reportuser = [
    {
      title: "Today Vehicles Registration",
      iconClass: "bx bxs-user",
      description: users.todayVechileRegistrations,
    },
    {
      title: "Today Payments",
      iconClass: "bx bxs-user",
      description: users.todayPayments,
    },
    {
      title: "Today Enquiries",
      iconClass: "bx bxs-user",
      description: users.todayEnquiries,
    },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"Star Routes"} breadcrumbItem={"Dashboard"} />
          <Row>
            <Col xl="12">
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Row>
                      <Col lg="12">
                        <div className="d-flex">
                          <div className="flex-grow-1 align-self-center">
                            <div className="text-muted">
                              <h5 className="mb-4">
                                <b>Dashboard</b>
                              </h5>
                              <Row>
                                <Col xs="4">
                                  <div>
                                    <h1 className="mb-0">
                                      <b>{users.totalVechileRegistrations}</b>
                                    </h1>
                                    <p className="text-muted text-truncate mb-2">
                                      Total Vehicles Registration
                                    </p>
                                  </div>
                                </Col>
                                <Col xs="4">
                                  <div>
                                    <h1 className="mb-0">
                                      <b>{users.totalPayments}</b>
                                    </h1>
                                    <p className="text-muted text-truncate mb-2">
                                      Total Payments
                                    </p>
                                  </div>
                                </Col>
                                <Col xs="4">
                                  <div>
                                    <h1 className="mb-0">
                                      <b>{users.totalEnquiries}</b>
                                    </h1>
                                    <p className="text-muted text-truncate mb-2">
                                      Total Enquiries
                                    </p>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <h5>
                <b></b>
              </h5>
              <Col xl="12">
                <Row>
                  {reportuser.map((report, key) => (
                    <Col md="4" key={"_col_" + key}>
                      <Card className="mini-stats-wid">
                        <CardBody>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <p className="text-muted fw-medium">
                                {report.title}
                              </p>
                              <h4 className="mb-0">{report.description}</h4>
                            </div>
                            <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                              <span className="avatar-title rounded-circle bg-primary">
                                <i
                                  className={
                                    "bx " + report.iconClass + " font-size-24"
                                  }
                                ></i>
                              </span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Col>
          </Row>
          <Row>
            <Col className="col-md-12">
              <Card>
                <CardHeader className="bg-white">
                  <CardTitle>
                    <h5 className="mt-3">
                      <b> Latest Enquiries </b>
                    </h5>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <div className="table-responsive">
                    <Table className="table table-bordered mb-4 ">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Name</th>
                          <th>Phone</th>
                          <th>Email</th>
                          <th>Company Name</th>
                          <th>Your Enquiry</th>
                        </tr>
                      </thead>
                      <tbody>
                        {users1.map((data, key) => (
                          <tr key={key}>
                            <th scope="row">{key + 1}</th>
                            <td>{data.name}</td>
                            <td>{data.phoneNumber}</td>
                            <td>{data.email}</td>
                            <td>{data.companyName}</td>
                            <td>{data.yourEnquiry}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default index
