const Url = "https://api.starroutes.com/"

export const URLS = {
  Base: Url,

  //profile
  getProfile: Url + "v1/staroutesapi/admin/auth/getprofile",
  UpdateProfile: Url + "v1/staroutesapi/admin/auth/updateadminprofile",
  UpdateImage: Url + "v1/staroutesapi/admin/auth/getprofileImg",
  ChangePass: Url + "v1/staroutesapi/admin/auth/changepass",

  //Team
  AddTeam: Url + "v1/staroutesapi/admin/team/addteam",
  GetTeam: Url + "v1/staroutesapi/admin/team/getallteams",
  GetTeamSearch: Url + "v1/staroutesapi/admin/team/getallteams?searchQuery=",
  UpdateTeam: Url + "v1/staroutesapi/admin/team/editteam/",
  DeleteTeam: Url + "v1/staroutesapi/admin/team/deleteteam/",

  //Blog
  AddBlog: Url + "v1/staroutesapi/admin/blog/addblog",
  GetBlog: Url + "v1/staroutesapi/admin/blog/getallblogs",
  GetBlogSearch: Url + "v1/staroutesapi/admin/blog/getallblogs?searchQuery=",
  UpdateBlog: Url + "v1/staroutesapi/admin/blog/editBlog/",
  DeleteBlog: Url + "v1/staroutesapi/admin/blog/deleteBlog/",

  //Industry
  AddIndustry: Url + "v1/staroutesapi/admin/industry/addindustry",
  GetIndustry: Url + "v1/staroutesapi/admin/industry/getAll",
  GetIndustrySearch: Url + "v1/staroutesapi/admin/industry/getAll?searchQuery=",
  UpdateIndustry: Url + "v1/staroutesapi/admin/industry/editindustry/",
  DeleteIndustry: Url + "v1/staroutesapi/admin/industry/deleteindustry/",

  //Feet Business
  AddFeetBusiness: Url + "v1/staroutesapi/admin/fleetbusiness/addfleetbusiness",
  GetFeetBusiness:
    Url + "v1/staroutesapi/admin/fleetbusiness/getallfleetbusiness",
  GetFeetBusinessSearch:
    Url +
    "/v1/staroutesapi/admin/fleetbusiness/getallfleetbusiness?searchQuery=",
  UpdateFeetBusiness:
    Url + "v1/staroutesapi/admin/fleetbusiness/editfleetbusiness/",
  DeleteFeetBusiness:
    Url + "v1/staroutesapi/admin/fleetbusiness/deletefleetbusiness/",

  //Products
  AddProducts: Url + "v1/staroutesapi/admin/product/addproduct",
  GetProducts: Url + "v1/staroutesapi/admin/product/getallproducts",
  GetProductsSearch:
    Url + "v1/staroutesapi/admin/product/getallproducts?searchQuery=",
  UpdateProducts: Url + "v1/staroutesapi/admin/product/editproduct/",
  DeleteProducts: Url + "v1/staroutesapi/admin/product/deleteproduct/",

  //slide
  Addslide: Url + "v1/staroutesapi/admin/slide/addslide",
  Getslide: Url + "v1/staroutesapi/admin/slide/getallslides",
  GetslideSearch: Url + "v1/staroutesapi/admin/slide/getallslides?searchQuery=",
  Updateslide: Url + "v1/staroutesapi/admin/slide/editslide/",
  Deleteslide: Url + "v1/staroutesapi/admin/slide/deleteslide/",

  //Service
  AddService: Url + "v1/staroutesapi/admin/category/addcategory",
  GetService: Url + "v1/staroutesapi/admin/category/getallcategory",
  GetServiceSearch:
    Url + "v1/staroutesapi/admin/category/getallcategory?searchQuery=",
  UpdateService: Url + "v1/staroutesapi/admin/category/editcategory/",
  DeleteService: Url + "v1/staroutesapi/admin/category/deletecategory/",

  //About Us
  GetAbout: Url + "v1/staroutesapi/admin/aboutus/getaboutus",
  UpdateAbout: Url + "v1/staroutesapi/admin/aboutus/editaboutus",

  //Vehicle
  GetVehicle: Url + "v1/staroutesapi/admin/getvechiles",
  GetVehicleSearch: Url + "v1/staroutesapi/admin/getvechiles?searchQuery=",
  GetIdVehicle: Url + "v1/staroutesapi/admin/getvechilebyid",
  DeleteVehicle: Url + "v1/staroutesapi/admin/deletevechile/",

  //Payments
  GetPayments: Url + "v1/staroutesapi/admin/getpayments",
  GetPaymentsSearch: Url + "v1/staroutesapi/admin/getpayments?searchQuery=",
  GetIdPayments: Url + "v1/staroutesapi/admin/getpaymentbyid",
  DeletePayments: Url + "v1/staroutesapi/admin/deletepayment/",

  //ENQUIRY
  GetEnquiry: Url + "v1/staroutesapi/admin/getAllenquiry",
  GetEnquirySearch: Url + "v1/staroutesapi/admin/getAllenquiry?searchQuery=",
  DeleteEnquiry: Url + "v1/staroutesapi/admin/deleteenquiry/",

  //Service ENQUIRY
  GetServiceENQUIRY: Url + "v1/staroutesapi/admin/getallcategoryforms",
  GetServiceENQUIRYSearch: Url + "v1/staroutesapi/admin/getallcategoryforms?searchQuery=",
  DeleteServiceENQUIRY: Url + "v1/staroutesapi/admin/deletecategoryform/",

  //Roadside
  GetRoadside: Url + "v1/staroutesapi/admin/getallroadsideassistances",
  GetRoadsideSearch:
    Url + "v1/staroutesapi/admin/getallroadsideassistances?searchQuery=",
  DeleteRoadside: Url + "v1/staroutesapi/admin/deleteroadsideassistance/",

  //Insurance
  GetInsurance: Url + "v1/staroutesapi/admin/getallinsurances",
  GetInsuranceSearch:
    Url + "v1/staroutesapi/admin/getallinsurances?searchQuery=",
  DeleteInsurance: Url + "v1/staroutesapi/admin/deleteinsurance/",

  //Address
  GetAddress: Url + "v1/staroutesapi/admin/address/getaddress",
  UpdateAddress: Url + "v1/staroutesapi/admin/address/editaddress",

  //Faqs
  AddFaqs: Url + "v1/staroutesapi/admin/faq/addfaq",
  GetFaqs: Url + "v1/staroutesapi/admin/faq/getAll",
  GetFaqsSearch: Url + "v1/staroutesapi/admin/faq/getAll?searchQuery=",
  DeleteFaqs: Url + "v1/staroutesapi/admin/faq/deletefaq",
  UpdateFaqs: Url + "v1/staroutesapi/admin/faq/editfaq",

  //QucickWalletFaqs
  AddQucickWalletFaqs:
    Url + "v1/staroutesapi/admin/quickwalletfaq/addquickwalletfaq",
  GetQucickWalletFaqs:
    Url + "v1/staroutesapi/admin/quickwalletfaq/getallquickwalletfaq",
  GetQucickWalletFaqsSearch:
    Url +
    "v1/staroutesapi/admin/quickwalletfaq/getallquickwalletfaq?searchQuery=",
  DeleteQucickWalletFaqs:
    Url + "v1/staroutesapi/admin/quickwalletfaq/deletequickwalletfaq",
  UpdateQucickWalletFaqs:
    Url + "v1/staroutesapi/admin/quickwalletfaq/editquickwalletfaq",

  //CorporateGovernance
  AddCorporateGovernance:
    Url + "v1/staroutesapi/admin/corporateGovernance/addcorporategovernance",
  GetCorporateGovernance:
    Url + "v1/staroutesapi/admin/corporateGovernance/getallcorporategovernance",
  GetCorporateGovernanceSearch:
    Url +
    "v1/staroutesapi/admin/corporateGovernance/getallcorporategovernance?searchQuery=",
  DeleteCorporateGovernance:
    Url +
    "v1/staroutesapi/admin/corporateGovernance/deletecorporategovernance/",
  UpdateCorporateGovernance:
    Url + "v1/staroutesapi/admin/corporateGovernance/editcorporategovernance/",

  //Settings
  GetSettings: Url + "v1/staroutesapi/admin/setting/getSetting",
  UpdatePrivicyPolicy: Url + "v1/staroutesapi/admin/setting/editprivacypolicy",
  UpdateTermsandConditions:
    Url + "v1/staroutesapi/admin/setting/edittermsandcondition",
  UpdateRefundpolicy: Url + "v1/staroutesapi/admin/setting/editrefundpolicy",

  GetDashboard: Url + "v1/staroutesapi/admin/getDasboard",
}
