import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Button,
  Table,
  Label,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"
import { ToastContainer, toast } from "react-toastify"
import { URLS } from "../../Url"

const Staff = () => {
  const [show1, setshow1] = useState(false)
  const toggle = () => setshow1(!show1)

  const [users, setusers] = useState([])
  const [form1, setform1] = useState([])

  const handleChange1 = e => {
    let myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const Get = () => {
    var token = datas
    axios
      .post(
        URLS.GetAddress,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setusers(res.data.address)
        setform1(res.data.address)
      })
  }

  const Update = () => {
    var token = datas
    const dataArray = {
      address: form1.address,
      phone: form1.phone,
      email: form1.email,
    }
    axios
      .put(URLS.UpdateAddress, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)

            setshow1(false)
            Get()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const formeditsubmit = e => {
    e.preventDefault()
    Update()
  }

  const usedata = () => {
    setshow1(true)
  }

  useEffect(() => {
    Get()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Star Routes" breadcrumbItem="Address" />

          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Button
                    onClick={() => {
                      usedata(data)
                    }}
                    size="sm"
                    className="m-1"
                    outline
                    color="success"
                  >
                    <i style={{ fontSize: " 14px" }} className="bx bx-edit"></i>{" "}
                    Edit
                  </Button>
                  <div className="table-rep-plugin mt-4 table-responsive">
                    <Table hover bordered responsive>
                      <thead>
                        <tr className="text-center">
                          <th>Email </th>
                          <td>{users.email}</td>
                        </tr>
                        <tr className="text-center">
                          <th>Phone</th>
                          <td>{users.phone}</td>{" "}
                        </tr>

                        <tr className="text-center">
                          <th>Address</th>
                          <td>{users.address}</td>{" "}
                        </tr>
                      </thead>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <ToastContainer />
      </div>
      <Modal size="lg" isOpen={show1} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>Edit About Us </ModalHeader>
        <ModalBody>
          <Form
            onSubmit={e => {
              formeditsubmit(e)
            }}
          >
            <Row className="pt-3">
              <Col md="6">
                <div className="mb-3">
                  <Label> Email </Label> <span className="text-danger">*</span>
                  <Input
                    name="email"
                    onChange={e => {
                      handleChange1(e)
                    }}
                    value={form1.email}
                    required
                    type="email"
                    placeholder="Enter Email "
                  />
                </div>
              </Col>

              <Col md="6">
                {" "}
                <div className="mb-3">
                  <Label> Phone </Label> <span className="text-danger">*</span>
                  <Input
                    name="phone"
                    onChange={e => {
                      handleChange1(e)
                    }}
                    value={form1.phone}
                    required
                    type="number"
                    placeholder="Enter Phone"
                  />
                </div>
              </Col>

              <Col md="12">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    Address <span className="text-danger">*</span>
                  </Label>
                  <textarea
                    type="text"
                    className="form-control"
                    id="basicpill-firstname-input1"
                    placeholder="Enter Address"
                    required
                    rows={3}
                    value={form1.address}
                    name="address"
                    onChange={e => {
                      handleChange1(e)
                    }}
                  />
                </div>
              </Col>
            </Row>
            <div className="text-end mt-3">
              <Button type="submit" color="primary m-1" outline>
                Submit <i className="bx bx-check-circle"></i>
              </Button>
              <Button
                type="button"
                onClick={() => {
                  setshow1(!show1)
                }}
                color="danger m-1"
                outline
              >
                Cancel <i className="bx bx-x-circle"></i>
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default Staff
