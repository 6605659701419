import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

//sliders
import Sliders from "pages/Components/Sliders"

//industry
import Industry from "pages/Components/Industry"

//about us
import Aboutus from "pages/Components/Aboutus/Aboutus"
import Team from "pages/Components/Aboutus/Team"

//contact us
import Settings from "pages/Components/Contact Us/Settings"
import Queries from "pages/Components/Contact Us/Queries"

//blog
import Blog from "pages/Components/Blog/Blog"

//VEHICLE DETAILS
import Vehicles from "pages/Components/Vehicle Details/Vehicles"
import Vehicledetails from "pages/Components/Vehicle Details/Vehicledetails"
import Payments from "pages/Components/Vehicle Details/Payments"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

import Faqs from "pages/Components/Faqs"
import QuickWalleyt from "pages/Components/QuickWalleyt"
import PrivicyPolicy from "pages/Components/PrivicyPolicy"
import RefundPolicy from "pages/Components/RefundPolicy"
import TermsAndConditions from "pages/Components/TermsAndConditions"
import Address from "pages/Components/Address"

import CorporateGovernance from "pages/Components/CorporateGovernance"

import Insurance from "pages/Components/Services/Insurance"
import Products from "pages/Components/Services/Products"
import RoadSide from "pages/Components/Services/RoadSide"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

import FeetBussness from "pages/Components/FeetBussness"

import Services from "pages/Components/Services/Services"

import Enquiry from "pages/Components/Services/Enquiry"

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/profile", component: UserProfile },

  //Home
  { path: "/Sliders", component: Sliders },
  { path: "/Industry", component: Industry },
  { path: "/FeetBussness", component: FeetBussness },

  //Services
  { path: "/Insurance", component: Insurance },
  { path: "/Products", component: Products },
  { path: "/RoadSide", component: RoadSide },
  { path: "/Services", component: Services },
  { path: "/Enquiry", component: Enquiry },

  //ABOUT US
  { path: "/Aboutus", component: Aboutus },
  { path: "/Team", component: Team },
  { path: "/Vehicles", component: Vehicles },

  //CONTACT US
  { path: "/Settings", component: Settings },
  { path: "/Queries", component: Queries },

  { path: "/Faqs", component: Faqs },
  { path: "/QuickWalleyt", component: QuickWalleyt },
  { path: "/PrivicyPolicy", component: PrivicyPolicy },
  { path: "/RefundPolicy", component: RefundPolicy },
  { path: "/TermsAndConditions", component: TermsAndConditions },
  { path: "/Address", component: Address },

  //blog
  { path: "/Blog", component: Blog },

  { path: "/CorporateGovernance", component: CorporateGovernance },

  //VEHICLE DETAILS
  { path: "/Vehicledetails", component: Vehicledetails },
  { path: "/Payments", component: Payments },

  { path: "/", exact: true, component: () => <Redirect to="/login" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
]

export { publicRoutes, authProtectedRoutes }
