import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Button,
  Table,
  Label,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import axios from "axios"
import { ToastContainer, toast } from "react-toastify"
import { URLS } from "../../../Url"

const Staff = () => {
  const [show1, setshow1] = useState(false)
  const toggle = () => setshow1(!show1)

  const [files1, setFiles1] = useState([])

  const changeHandler1 = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png"|| type == "JPG"||  type == "JPEG"||type == "PNG" ) {
      setFiles1(e.target.files)
    } else {
      e.target.value = null
      toast("file format not supported.Pls choose Image")
    }
  }

  const [files2, setFiles2] = useState([])

  const changeHandler2 = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png"|| type == "JPG"||  type == "JPEG"||type == "PNG"  ) {
      setFiles2(e.target.files)
    } else {
      e.target.value = null
      toast("file format not supported.Pls choose Image")
    }
  }
  const [files3, setFiles3] = useState([])

  const changeHandler3 = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png"|| type == "JPG"||  type == "JPEG"||type == "PNG"  ) {
      setFiles3(e.target.files)
    } else {
      e.target.value = null
      toast("file format not supported.Pls choose Image")
    }
  }
  const [files4, setFiles4] = useState([])

  const changeHandler4 = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png"|| type == "JPG"||  type == "JPEG"||type == "PNG"  ) {
      setFiles4(e.target.files)
    } else {
      e.target.value = null
      toast("file format not supported.Pls choose Image")
    }
  }
  const [files5, setFiles5] = useState([])

  const changeHandler5 = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png"|| type == "JPG"||  type == "JPEG"||type == "PNG" ) {
      setFiles5(e.target.files)
    } else {
      e.target.value = null
      toast("file format not supported.Pls choose Image")
    }
  }
  const [files6, setFiles6] = useState([])

  const changeHandler6 = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png"|| type == "JPG"||  type == "JPEG"||type == "PNG"  ) {
      setFiles6(e.target.files)
    } else {
      e.target.value = null
      toast("file format not supported.Pls choose Image")
    }
  }

  const [users, setusers] = useState([])
  const [form1, setform1] = useState([])

  const handleChange1 = e => {
    let myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const Get = () => {
    var token = datas
    axios
      .post(
        URLS.GetAbout,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setusers(res.data.aboutus)
        setform1(res.data.aboutus)
      })
  }

  const Update = () => {
    var token = datas
    const dataArray = new FormData()

    dataArray.append("about", form1.about)
    dataArray.append("description", form1.description)

    dataArray.append("title1", form1.title1)
    dataArray.append("title2", form1.title2)
    dataArray.append("title3", form1.title3)
    dataArray.append("title4", form1.title4)
    dataArray.append("title5", form1.title5)
    dataArray.append("title6", form1.title6)

    for (let i = 0; i < files1.length; i++) {
      dataArray.append("image1", files1[i])
    }

    for (let i = 0; i < files2.length; i++) {
      dataArray.append("image2", files2[i])
    }

    for (let i = 0; i < files3.length; i++) {
      dataArray.append("image3", files3[i])
    }

    for (let i = 0; i < files4.length; i++) {
      dataArray.append("image4", files4[i])
    }

    for (let i = 0; i < files5.length; i++) {
      dataArray.append("image5", files5[i])
    }

    for (let i = 0; i < files6.length; i++) {
      dataArray.append("image6", files6[i])
    }

    axios
      .put(URLS.UpdateAbout, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            setFiles1({ image: "" })
            setFiles2({ image: "" })
            setFiles3({ image: "" })
            setFiles4({ image: "" })
            setFiles5({ image: "" })
            setFiles6({ image: "" })
            setshow1(false)
            Get()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const formeditsubmit = e => {
    e.preventDefault()
    Update()
  }

  const usedata = () => {
    setshow1(true)
  }

  useEffect(() => {
    Get()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Star Routes" breadcrumbItem="About Us" />

          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Button
                    onClick={() => {
                      usedata(data)
                    }}
                    size="sm"
                    className="m-1"
                    outline
                    color="success"
                  >
                    <i style={{ fontSize: " 14px" }} className="bx bx-edit"></i>{" "}
                    Edit
                  </Button>
                  <div className="table-rep-plugin mt-4 table-responsive">
                    <Table hover bordered responsive>
                      <thead>
                        <tr className="text-center">
                          <th>Title</th>
                          <td>{users.title1}</td>

                          <th>Image</th>
                          <td>
                            {" "}
                            <img
                              src={URLS.Base + users.image1}
                              height="60px"
                              width="80px"
                            ></img>
                          </td>
                        </tr>

                        <tr className="text-center">
                          <th>Title</th>
                          <td>{users.title2}</td>

                          <th>Image</th>
                          <td>
                            {" "}
                            <img
                              src={URLS.Base + users.image2}
                              height="60px"
                              width="80px"
                            ></img>
                          </td>
                        </tr>

                        <tr className="text-center">
                          <th>Title</th>
                          <td>{users.title3}</td>

                          <th>Image</th>
                          <td>
                            {" "}
                            <img
                              src={URLS.Base + users.image3}
                              height="60px"
                              width="80px"
                            ></img>
                          </td>
                        </tr>

                        <tr className="text-center">
                          <th>Title</th>
                          <td>{users.title4}</td>

                          <th>Image</th>
                          <td>
                            {" "}
                            <img
                              src={URLS.Base + users.image4}
                              height="60px"
                              width="80px"
                            ></img>
                          </td>
                        </tr>

                        <tr className="text-center">
                          <th>Title</th>
                          <td>{users.title5}</td>

                          <th>Image</th>
                          <td>
                            {" "}
                            <img
                              src={URLS.Base + users.image5}
                              height="60px"
                              width="80px"
                            ></img>
                          </td>
                        </tr>

                        <tr className="text-center">
                          <th>Title</th>
                          <td>{users.title6}</td>

                          <th>Image</th>
                          <td>
                            {" "}
                            <img
                              src={URLS.Base + users.image6}
                              height="60px"
                              width="80px"
                            ></img>
                          </td>
                        </tr>

                        <tr className="text-center">
                          <th>About Us</th>
                          <td>{users.about}</td>

                          <th>Description</th>
                          <td>{users.description}</td>
                        </tr>
                      </thead>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <ToastContainer />
      </div>
      <Modal size="lg" isOpen={show1} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>Edit About Us </ModalHeader>
        <ModalBody>
          <Form
            onSubmit={e => {
              formeditsubmit(e)
            }}
          >
            <Row className="pt-3">
              <Col md="6">
                <Label> Title </Label> <span className="text-danger">*</span>
                <Input
                  name="title1"
                  onChange={e => {
                    handleChange1(e)
                  }}
                  value={form1.title1}
                  required
                  type="text"
                  placeholder="Enter Title"
                />
              </Col>

              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    Image
                    <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="file"
                    className="form-control"
                    id="basicpill-firstname-input1"
                    placeholder="Enter image"
                    name="image"
                    onChange={changeHandler1}
                  />
                </div>
              </Col>

              <Col md="6">
                <Label> Title </Label> <span className="text-danger">*</span>
                <Input
                  name="title2"
                  onChange={e => {
                    handleChange1(e)
                  }}
                  value={form1.title2}
                  required
                  type="text"
                  placeholder="Enter Title"
                />
              </Col>

              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    Image
                    <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="file"
                    className="form-control"
                    id="basicpill-firstname-input1"
                    placeholder="Enter image"
                    name="image"
                    onChange={changeHandler2}
                  />
                </div>
              </Col>

              <Col md="6">
                <Label> Title </Label> <span className="text-danger">*</span>
                <Input
                  name="title3"
                  onChange={e => {
                    handleChange1(e)
                  }}
                  value={form1.title3}
                  required
                  type="text"
                  placeholder="Enter Title"
                />
              </Col>

              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    Image
                    <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="file"
                    className="form-control"
                    id="basicpill-firstname-input1"
                    placeholder="Enter image"
                    name="image"
                    onChange={changeHandler3}
                  />
                </div>
              </Col>

              <Col md="6">
                <Label> Title </Label> <span className="text-danger">*</span>
                <Input
                  name="title4"
                  onChange={e => {
                    handleChange1(e)
                  }}
                  value={form1.title4}
                  required
                  type="text"
                  placeholder="Enter Title"
                />
              </Col>

              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    Image
                    <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="file"
                    className="form-control"
                    id="basicpill-firstname-input1"
                    placeholder="Enter image"
                    name="image"
                    onChange={changeHandler4}
                  />
                </div>
              </Col>

              <Col md="6">
                <Label> Title </Label> <span className="text-danger">*</span>
                <Input
                  name="title5"
                  onChange={e => {
                    handleChange1(e)
                  }}
                  value={form1.title5}
                  required
                  type="text"
                  placeholder="Enter Title"
                />
              </Col>

              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    Image
                    <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="file"
                    className="form-control"
                    id="basicpill-firstname-input1"
                    placeholder="Enter image"
                    name="image"
                    onChange={changeHandler5}
                  />
                </div>
              </Col>

              <Col md="6">
                <Label> Title </Label> <span className="text-danger">*</span>
                <Input
                  name="title6"
                  onChange={e => {
                    handleChange1(e)
                  }}
                  value={form1.title6}
                  required
                  type="text"
                  placeholder="Enter Title"
                />
              </Col>

              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    Image
                    <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="file"
                    className="form-control"
                    id="basicpill-firstname-input1"
                    placeholder="Enter image"
                    name="image"
                    onChange={changeHandler6}
                  />
                </div>
              </Col>

              <Col md="6">
                <Label> About Us </Label> <span className="text-danger">*</span>
                <Input
                  name="about"
                  onChange={e => {
                    handleChange1(e)
                  }}
                  value={form1.about}
                  required
                  type="text"
                  placeholder="Enter About Us"
                />
              </Col>

              <Col md="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    Description <span className="text-danger">*</span>
                  </Label>
                  <textarea
                    type="text"
                    className="form-control"
                    id="basicpill-firstname-input1"
                    placeholder="Enter Description"
                    required
                    rows={1}
                    value={form1.description}
                    name="description"
                    onChange={e => {
                      handleChange1(e)
                    }}
                  />
                </div>
              </Col>
            </Row>
            <div className="text-end mt-3">
              <Button type="submit" color="primary m-1" outline>
                Submit <i className="bx bx-check-circle"></i>
              </Button>
              <Button
                type="button"
                onClick={() => {
                  setshow1(!show1)
                }}
                color="danger m-1"
                outline
              >
                Cancel <i className="bx bx-x-circle"></i>
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default Staff
