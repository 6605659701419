import React, { useState, useEffect } from "react"
import { CardBody, Container, Row, Col, Card, Button } from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { ToastContainer } from "react-toastify"
import { useHistory } from "react-router-dom"
import axios from "axios"
import { URLS } from "../../../Url"

function State() {
  const [forms, setforms] = useState([])

  const history = useHistory()

  useEffect(() => {
    GetOneAgents()
  }, [])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const Agentid = sessionStorage.getItem("Vehiclesid")

  const GetOneAgents = () => {
    const data = {
      _id: Agentid,
    }

    var token = datas
    axios
      .post(URLS.GetIdVehicle, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setforms(res.data.data)
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Star Routes" breadcrumbItem="Vehicle Details" />
          <Row>
            <Col>
              <Button
                onClick={() => history.goBack()}
                className="mb-3  m-1 "
                style={{ float: "right" }}
                color="primary"
              >
                <i className="far fa-arrow-alt-circle-left"></i> Back
              </Button>
            </Col>
          </Row>

          <Row className="mb-5">
            <Col md={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col md={6}>
                      <h5 className="mb-3">Vehicle Details : </h5>
                    </Col>

                    <Col md={6}>
                      <div style={{ float: "right" }}>
                        <h6 className="mb-3">
                          Status :{" "}
                          {forms.status == "active" ? (
                            <>
                              <span className="badge bg-success ">
                                {forms.status}
                              </span>
                            </>
                          ) : (
                            <>
                              <span className="badge bg-danger ">
                                {forms.status}
                              </span>
                            </>
                          )}
                        </h6>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col lg={6}>
                      <ul className="verti-timeline list-unstyled">
                        <li className="event-list">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14 ">
                                  Driver Image
                                </h6>
                                <img
                                  src={URLS.Base + forms.driverImage}
                                  height="150px"
                                ></img>{" "}
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="event-list">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14 ">
                                  Vehicle Registration Number
                                </h6>
                                <p className="text-muted">
                                  {forms.registrationNumber}
                                </p>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="event-list">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14 ">Vehicle Class</h6>
                                <p className="text-muted">
                                  {forms.vehicleClass}
                                </p>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="event-list mt-4">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14 ">First Name:</h6>
                                <p className="text-muted">{forms.firstName}</p>
                              </div>
                            </div>
                          </div>
                        </li>{" "}
                        <li className="event-list">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14 ">Last Name</h6>
                                <p className="text-muted">{forms.lastName}</p>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="event-list">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14 ">Gender</h6>
                                <p className="text-muted">{forms.gender}</p>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="event-list">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14 ">Phone</h6>
                                <p className="text-muted">{forms.phone}</p>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="event-list">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14 ">Email</h6>
                                <p className="text-muted">{forms.email}</p>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </Col>

                    <Col lg={6}>
                      <ul className="verti-timeline list-unstyled">
                        <li className="event-list">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14 ">Date Of Birth</h6>
                                <p className="text-muted">
                                  {forms.dateOfBirth}
                                </p>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="event-list">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14 ">
                                  Aadhaar Number
                                </h6>
                                <p className="text-muted">
                                  {forms.adharcardNumber}
                                </p>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="event-list">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14 ">
                                  Pan Card Number
                                </h6>
                                <p className="text-muted">
                                  {forms.pancardNumber}
                                </p>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="event-list mt-4">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14 ">
                                  Address Line1:
                                </h6>
                                <p className="text-muted">
                                  {forms.addressLine1}
                                </p>
                              </div>
                            </div>
                          </div>
                        </li>{" "}
                        <li className="event-list">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14 ">Address Line2</h6>
                                <p className="text-muted">
                                  {forms.addressLine2}
                                </p>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="event-list">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14 ">Pincode</h6>
                                <p className="text-muted">{forms.pincode}</p>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="event-list">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14 ">District</h6>
                                <p className="text-muted">{forms.district}</p>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="event-list">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14 ">State</h6>
                                <p className="text-muted">{forms.state}</p>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="event-list">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14 ">Status</h6>
                                <p className="text-muted">{forms.status}</p>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </Col>
                  </Row>

                  <h5 className="mb-5 mt-5">Document : </h5>
                  <Row>
                    <Col lg={6}>
                      <ul className="verti-timeline list-unstyled">
                        <li className="event-list">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14 ">
                                  Aadhaar card Image
                                </h6>
                                <p className="text-muted">
                                  <img
                                    src={URLS.Base + forms.adhardcardImage}
                                    height="150px"
                                    width="50%"
                                    alt="empty"
                                  ></img>
                                </p>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="event-list">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14 ">
                                  Pan Card Image
                                </h6>
                                <p className="text-muted">
                                  <img
                                    src={URLS.Base + forms.pancardImage}
                                    height="150px"
                                    width="50%"
                                    alt="empty"
                                  ></img>
                                </p>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </Col>
                    <Col lg={6}>
                      <ul className="verti-timeline list-unstyled">
                        <li className="event-list">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14 ">Rc Front Copy</h6>
                                <p className="text-muted">
                                  <img
                                    src={URLS.Base + forms.rcFrontImage}
                                    height="150px"
                                    width="50%"
                                    alt="empty"
                                  ></img>
                                </p>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="event-list">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14 "> Rc Back Copy</h6>
                                <p className="text-muted">
                                  <img
                                    src={URLS.Base + forms.rcBackImage}
                                    height="150px"
                                    width="50%"
                                    alt="empty"
                                  ></img>
                                </p>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default State
