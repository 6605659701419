import React, { useState } from 'react'
import {
    CardBody,
    CardHeader,
    Container,
    Row,
    Col,
    Card,
    CardTitle,
    Form,
    Label,
    Input,
    Button,
    Table,
    Modal,
} from "reactstrap"
import ReactPaginate from "react-paginate"
import Breadcrumbs from "../../../components/Common/Breadcrumb"

function Settings() {
    const [modal_small, setmodal_small] = useState(false)
    const [banner, setbanner] = useState([])
    const [form, setform] = useState([])
    const [form1, setform1] = useState([])
    const getpopup = data => {
        setmodal_small(true)
        //   setform1(data)
        //   tog_small()
    }
    const clearForm = () => {
        setform({
            Email: "",
            Address: "",
            MobileNo: ""
        })
    }
    const clearForm1 = () => {
        setform1({
            Email: "",
            Address: "",
            MobileNo: ""
        })
    }
    var datas=[{
        sno:1,
        Email: "lakki@1234.com",
        Address: "hyderaabd",
        MobileNo: "15465465467"

    },{
        sno:2,
        Email: "ram@12345.com",
        Address: "guntur",
        MobileNo: "8955663266"
    }]
    const handleChange = () => {
        let myUser = { ...form }
        myUser[e.target.name] = e.target.value
        setform(myUser)
    }
    const dataArray = new FormData()


    dataArray.append("Email", form.Email)
    dataArray.append("Address", form.Address)
    dataArray.append("MobileNo", form.MobileNo)


    const handleSubmit = () => {
        e.preventDefault();
        clearForm();
    }
    const handleChange1 = () => {
        let myUser = { ...form1 }
        myUser[e.target.name] = e.target.value
        setform1(myUser)
    }
    const dataArray1 = new FormData()
    dataArray1.append("Email", form1.Email)
    dataArray1.append("Address", form1.Address)
    dataArray1.append("MobileNo", form1.MobileNo)

    const handleSubmit1 = () => {
        e.preventDefault();
        clearForm1();
    }
    const [listPerPage] = useState(5)
    const [pageNumber, setPageNumber] = useState(0)

    const pagesVisited = pageNumber * listPerPage
    const lists = banner.slice(pagesVisited, pagesVisited + listPerPage)
    const pageCount = Math.ceil(banner.length / listPerPage)
    const changePage = ({ selected }) => {
        setPageNumber(selected)
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <Container >
                    <Breadcrumbs title="Star Routes" breadcrumbItem="Settings" />
                    <Row>
                        {/* <Col className='col-md-4'>
                    <Card>
                        <CardHeader className="bg-white">
                            <CardTitle>Add Settings</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Form
                                onSubmit={e => {
                                    handleSubmit(e)
                                }}
                            >
                                <div className="mb-3">
                                    <Label for="basicpill-firstname-input1">
                                    Email<span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                        type="email"
                                        id="basicpill-firstname-input1"
                                        className="form-control"
                                        placeholder="Enter Email.."
                                        name="Email"
                                        value={form.Email}
                                        required
                                        onChange={e => {
                                            handleChange(e)
                                        }}
                                    />
                                </div>
                                <div className="mb-3">
                                    <Label for="basicpill-firstname-input1">
                                    Address<span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                        type="text"
                                        id="basicpill-firstname-input1"
                                        className="form-control"
                                        placeholder="Enter Address Name"
                                        name="Address"
                                        value={form.Address}
                                        required
                                        onChange={e => {
                                            handleChange(e)
                                        }}
                                    />
                                </div>
                                <div className="mb-3">
                                    <Label for="basicpill-firstname-input1">
                                    MobileNo<span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                        type="number"
                                        id="basicpill-firstname-input1"
                                        className="form-control"
                                        placeholder="Enter MobileNo Name"
                                        name="MobileNo"
                                        value={form.MobileNo}
                                        required
                                        onChange={e => {
                                            handleChange(e)
                                        }}
                                    />
                                </div>
                               

                            </Form>

                        </CardBody>
                    </Card>
                </Col> */}
                        <Col className='col-md-12'>
                            <Card>
                                <CardHeader className="bg-white">
                                    <CardTitle>Settings List</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <div className="table-responsive">
                                        <div style={{ float: "right" }}>
                                            <Input
                                                type="search"
                                                className="form-control"
                                                placeholder="Search.."

                                                name="search"
                                            />
                                        </div>
                                        <Table className="table table-bordered mb-4 mt-5">
                                            <thead>
                                                <tr>
                                                    <th>S.No</th>
                                                    <th>Email</th>
                                                    <th>Address</th>
                                                    <th>MobileNo</th>


                                                    <th style={{ width: "100px" }}>Action</th>



                                                </tr>
                                            </thead>
                                            <tbody>
                                                {datas.map((data, key) => (
                                                    <tr key={key}>
                                                        <td>{data.sno}</td>
                                                        <td>{data.Email}</td>
                                                        <td>{data.Address}</td>
                                                        <td>{data.MobileNo}</td>
                                                        <td>
                                                            <Button
                                                                onClick={() => {
                                                                    getpopup(data)
                                                                }}
                                                                className="mr-2"
                                                                style={{
                                                                    padding: "6px",
                                                                    margin: "3px",
                                                                }}
                                                                color="success"
                                                                outline
                                                            >
                                                                <i className="bx bx-edit "></i>
                                                            </Button>
                                                            <Button
                                                                onClick={() => {
                                                                    manageDelete(data)
                                                                }}
                                                                style={{
                                                                    padding: "6px",
                                                                    margin: "3px",
                                                                }}
                                                                color="danger"
                                                                outline
                                                            >
                                                                <i className="bx bx-trash"></i>
                                                            </Button>{" "}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={"pagination"}
                        previousLinkClassName={"previousBttn"}
                        nextLinkClassName={"nextBttn"}
                        disabledClassName={"disabled"}
                        activeClassName={"active"}
                        total={lists.length}
                    />

                </Container>
                <Modal
                    size="md"
                    isOpen={modal_small}
                    // toggle={() => {
                    //     tog_small()
                    // }}
                    centered
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="mySmallModalLabel">
                            Edit Settings
                        </h5>
                        <button
                            onClick={() => {
                                setmodal_small(false)
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Form
                            onSubmit={e => {
                                handleSubmit1(e)
                            }}
                        >
                            <div className="mb-3">
                                <Label for="basicpill-firstname-input1">
                                    Email<span className="text-danger">*</span>
                                </Label>
                                <Input
                                    type="email"
                                    id="basicpill-firstname-input1"
                                    className="form-control"
                                    placeholder="Enter Email.."
                                    name="Email"
                                    value={form.Email}
                                    required
                                    onChange={e => {
                                        handleChange(e)
                                    }}
                                />
                            </div>
                            <div className="mb-3">
                                <Label for="basicpill-firstname-input1">
                                    Address<span className="text-danger">*</span>
                                </Label>
                                <Input
                                    type="text"
                                    id="basicpill-firstname-input1"
                                    className="form-control"
                                    placeholder="Enter Address Name"
                                    name="Address"
                                    value={form.Address}
                                    required
                                    onChange={e => {
                                        handleChange(e)
                                    }}
                                />
                            </div>
                            <div className="mb-3">
                                <Label for="basicpill-firstname-input1">
                                    MobileNo<span className="text-danger">*</span>
                                </Label>
                                <Input
                                    type="number"
                                    id="basicpill-firstname-input1"
                                    className="form-control"
                                    placeholder="Enter MobileNo Name"
                                    name="MobileNo"
                                    value={form.MobileNo}
                                    required
                                    onChange={e => {
                                        handleChange(e)
                                    }}
                                />
                            </div>


                            <div style={{ float: "right" }}>
                                <Button
                                    onClick={() => {
                                        setmodal_small(false)
                                    }}
                                    color="danger"
                                    type="button"
                                >
                                    Cancel <i className="fas fa-times-circle"></i>
                                </Button>
                                <Button className="m-1" color="success" type="submit">
                                    Submit <i className="fas fa-check-circle"></i>
                                </Button>
                            </div>
                        </Form>
                    </div>
                </Modal>

            </div>
        </React.Fragment>
    )
}

export default Settings